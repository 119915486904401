import { createApp } from 'vue'
import App from './App.vue'

import Notifications from '@kyvg/vue3-notification'

// const app = createApp(App)
// app.mount('#app')
// app.use(Notifications)

// createApp(App).mount('#app')
const app = createApp(App);
app.use(Notifications);

app.mount("#app");