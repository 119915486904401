<template>
  <section>
    <nav class="navbar fixed-top navbar-expand-sm navbar-dark bg-dark">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <a class="navbar-brand" href="#" @click="store.state.mindenekelott = 1; store.state.sorozat_rebi = 0;"
          >Sori-progi</a
        >
        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul
            class="navbar-nav me-auto mb-2 mb-lg-0"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show"
          >
            <li class="nav-item">
              <a class="nav-link" @click="form_megjelenites" href="#"
                >Hozzáadás</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#"
                @click="store.state.mindenekelott = 2"
                >Rejtettek</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#"
                @click="store.state.sorozat_rebi = 1"
                >Rebi</a
              >
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Beállítások
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <div class="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        class="mx-2"
                        v-model="store.state.beallitasok.megnyit"
                      />Megnyitás</label
                    >
                  </div>
                </li>
                <li>
                  <div class="checkbox">
                    <label> <input type="checkbox" class="mx-2" />First</label>
                  </div>
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <div class="checkbox">
                    <label> <input type="checkbox" class="mx-2" />First</label>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
          <div class="d-flex">
            <span v-if="!isLoggedIn">
              <div class="input-group input-group-sm">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  size="18"
                   v-model="email" 
                />
                <input
                type="password"
                placeholder="Password"
                v-model="password"
                class="form-control" 
                size="4"
              />
                <button
                  @click="signIn" class="btn  btn-outline-secondary btn-sm"
                  type="button"
                  id="button-addon2"
                >
                  Sign in
                </button>
              </div>
              
                  
            </span>
            <span v-if="isLoggedIn">
              <button @click="signOut" class="btn btn-light btn-sm">
                Logout
              </button>
            </span>
          </div>
        </div>
      </div>
    </nav>
  </section>
</template>
<script>
import { inject } from "vue";
import { ref } from "vue";
import db from "../db.js";
import { notify } from "@kyvg/vue3-notification";

export default {
  setup() {
    const store = inject("store");
    const form_megjelenites = function () {
      store.state.form_nemlathato = !store.state.form_nemlathato;
      window.scrollTo(0, 0);
      // /console.log(messages);
    };
    const isLoggedIn = ref();
    const email = ref("");
    const password = ref("");
    const signIn = () => {
      // we also renamed this method
      db.auth()
        .signInWithEmailAndPassword(email.value, password.value)
        .then((userCredential) => {
          // Signed in
           var user = userCredential.user;
          console.log(user);
          
        })
        .catch((error) => {
          // var errorCode = error.code;
          console.log(error.message);
           notify({
  title: "Warning",
  text: error.message,
   type: 'warn',
});
        });
    };

    // runs after firebase is initialized
 
    db.auth().onAuthStateChanged(function (user) {
      if (user) {
          
        isLoggedIn.value = true; // if we have a user
        store.state.user = true;
        if (user.uid == "jxUnuUYmBeZpdFts5TwNXTLuPXB2")
        // if (user.id == "VrGEC5sO59Nu5ow85CcWY3e7SdR2")
        {
        
          store.state.sorozat_rebibejel = 0;
        }
        else{
          store.state.sorozat_rebibejel = 1;
        }
      } else {
        isLoggedIn.value = false; // if we do not
        store.state.user = false;
      }
    });
    const signOut = () => {
      db.auth().signOut();
    };

    return {
      store,
      form_megjelenites,
      signIn,
      email,
      password,
      signOut,
      isLoggedIn,
    };
  },
};
</script>
